import React, {useEffect, useState} from "react";
import {Container} from "aurelia-dependency-injection";
import {DialogService} from "aurelia-dialog";
import {I18N} from "aurelia-i18n";
import {createUseStyles} from "react-jss";
import useApiFetch, {useModelChoiceSearch} from "../api/use-fetch";
import {UniversalEntitySelect} from "../dialog/universal-entity-select";
import {currencies} from "../currency/model/currencies.model";
import {Col, DatePicker, Dropdown, FlexboxGrid, Form, InputGroup, InputNumber, InputPicker, Loader} from "rsuite"
import SioIcon from "../icon/rsuite-icon-font/SioIcon";
import {useDebouncedChange} from "../utilities/debounce";
import {useFormChoiceQuery} from "../store/api";
import debounceLib from 'debounce-promise';
import { useMemo } from 'react';

const i18n = Container.instance.get(I18N);
const dialogService = Container.instance.get(DialogService);
const useStyles = createUseStyles({
    inputPicker: {
        ".rs-form-vertical .rs-form-group &.rs-input-group": {
            width: "100%",
            display: "flex",
            "& > .rs-picker-input": {
                flexGrow: 1,
                width: "inherit",
                borderWidth: 0
            },
            "& > .rs-dropdown > .rs-dropdown-toggle": {
                paddingRight: '6px',
                borderRadius: '0',
                marginLeft: '0',
                border: '0',
                "& > .rs-dropdown-toggle-caret": {
                    display: "none"
                }
            }
        }
    }
});

export const FormItem = ({label, field, accepter, col, ...props}) => {

    col = Object.assign({xs: 12, sm: 8, md: 6, lg: 4}, col ?? {});

    return (
        <FlexboxGrid.Item as={Col} {...col}>
            <Form.Group controlId={field}>
                <Form.ControlLabel>{label}</Form.ControlLabel>
                <Form.Control name={field} accepter={accepter} {...props}/>
            </Form.Group>
        </FlexboxGrid.Item>
    );
};

const mapToString = value => value?.id ? value.modelId + ":" + value.id : null
const mapToObjectRef = value => {
    if (!value) {
        return null
    }

    const [modelId, id] = value.split(":")
    return {id, modelId}
}

export const EntityPicker = ({modelId, conditions, value, valueMapper, onChange, ...props}) => {
    const [search, setSearch] = useState("");
    const {loading, items} = useModelChoiceSearch(modelId, search, conditions, value);
    const results = (value?.id ? 1 : 0) < (items || []).length;

    return (
        <InputPicker
            block
            data={(valueMapper ? items.map(valueMapper) : items).map(({label, value}) => ({
                label,
                value: mapToString(value)
            }))}
            onSearch={word => setSearch(word)}
            placement="auto"
            sort={() => ({label: a}, {label: b}) => a.localeCompare(b)}
            value={valueMapper ? mapToString(value) : value?.id ? mapToString({
                id: value.id,
                modelId: value.modelId
            }) : null}
            searchBy={(keyword, label) => label.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())}
            renderValue={(_, item) => loading || undefined === item ? <Loader/> : item?.label}
            renderMenuItem={label => "" !== search ?
                <span dangerouslySetInnerHTML={{__html: label.replace(search, "<b>" + search + "</b>")}}/> : label}
            renderMenu={menu => !loading && results ? menu : (
                <div className="text-center">
                    {loading ? <Loader content="Suche läuft …"/> : "Keine Suchergebnisse"}
                </div>
            )}
            onChange={value => onChange(mapToObjectRef(value))}
            {...props}
        />
    );
};

export const EntityPickerWithSelect = ({modelId, conditions = {}, value, onChange, ...props}) => {
    const {loading, data: models} = useApiFetch("interface/implementations/" + modelId);
    const {inputPicker} = useStyles();

    const selectFromTable = (modelId, event) => dialogService
        .open({viewModel: UniversalEntitySelect, model: {selectModelId: modelId}})
        .whenClosed(({wasCancelled, output}) => wasCancelled || onChange(output[0], event));

    return (
        <InputGroup className={inputPicker}>
            <EntityPicker modelId={modelId} conditions={conditions} value={value} onChange={onChange} {...props}/>
            <Dropdown className="rs-input-group-btn" icon={<SioIcon icon="fa fa-table-list"/>}
                      placement="topEnd">
                {loading && <Dropdown.Item icon={<Loader/>}/>}
                {(models || []).map(({id, moduleId, label}) => {
                    const modelId = moduleId + "/" + id;
                    return (
                        <Dropdown.Item key={modelId} onClick={event => selectFromTable(modelId, event)}>
                            {i18n.tr(label)}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown>
        </InputGroup>
    );
};

export const CurrencyInput = ({name, value, defaultValue, onChange, debounce, size = "md", ...props}) => {
    const currency = currencies[defaultValue?.currency ?? value?.currency ?? "EUR"];
    const factor = 10 ** currency.decimal_digits;
    let [amount, setAmount] = useState("" + ((value?.amount ?? defaultValue?.amount) / factor).toFixed(2));
    const [numberInput, setNumberInput] = useState("" + ((value?.amount ?? defaultValue?.amount) / factor).toFixed(2));
    if (debounce) {
        [amount, onChange] = useDebouncedChange(amount, debounce, onChange)
    }

    useEffect(() => {
        const newAmount = "" + ((value?.amount ?? defaultValue?.amount) / factor).toFixed(2)
        if (amount !== newAmount) {
            setAmount(newAmount)
        }
    }, [value?.amount, value?.currency]);
 
    const debouncedSetData = useMemo(
        () => debounceLib((value,event) => setValueInput(value,event), 1000),
        []
    );
    const setValueInput = (amount,event) => {
        if(!amount.includes(',')){
            setAmount("" + amount)
            const intAmount = Math.round(amount * factor).toFixed(2)
            if (intAmount !== value?.amount) {
                onChange({amount: intAmount, currency: currency.code}, event)
            }
        } 
    }
    return (
        <InputNumber
            size={size}
            // value={numberInput}
            defaultValue={numberInput}
            step={10 ** -currency.decimal_digits}
            lang="de"
            decimalSeparator=","
            onChange={(amount, event) => {
                if(!amount.includes(',')){
                    setNumberInput(amount)
                    debouncedSetData(amount,event)            
                }
            }}
            postfix={currency.symbol}
            {...props}
        />
    )
}

export const PercentageInput = ({name, value, defaultValue, onChange, ...props}) => {
    return (
        <InputNumber
            align="right"
            value={value ? value * 100 : ""}
            defaultValue={defaultValue ? defaultValue * 100 : undefined}
            step={.1}
            style={{width: "100%"}}
            lang="de"
            onChange={(value, event) => onChange(.01 * value, event)}
            postfix="%"
            {...props}
        />
    )
}

export const DateSelect = props => (
    <DatePicker block format="dd.MM.yyyy" placement="topStart" {...props}/>
);

export function ChoiceSetPicker({set, ...props}) {
    const {isLoading, isFetching, data} = useFormChoiceQuery(set)

    if (isLoading) {
        return <Loader/>
    }

    return (
        <InputPicker data={data} loading={isFetching} {...props}/>
    )
}
